/* eslint-env jquery */
import 'slick-carousel';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'requestidlecallback-polyfill';

// import { gsap } from 'gsap';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
// import Labels from './modules/Labels';
import Tabs from './modules/Tabs';
import Sliders from './modules/Sliders';
import TaskSchedulerInstance from './modules/TaskScheduler';
import MenuScroll from './modules/MenuScroll';

require('object-fit-videos');
require('object-fit-images');


const main = async () =>{
	
	/* formulaire baromètre */
	jQuery(document).on('gform_post_render',  () => {

		import(
			/* webpackChunkName: "labels" */
			'./modules/Labels').then( moduleLabels => {
			const labels = new moduleLabels.Labels();
			labels.init();
		});

		[].forEach.call(document.querySelectorAll("input:checked"), el => {
			const parent = el.parentNode;
			parent.classList.add('iii-b-selected');
		});

		// code to trigger on form or form page render
		[].forEach.call(document.querySelectorAll(".ginput_container_radio li"), el => {
			el.addEventListener('click', e => {
				const target = e.currentTarget ;
				const ulNode = target.parentNode;
				const radioButton = target.querySelector('input');
				//	Clear selected item
				[].forEach.call(ulNode.querySelectorAll(".iii-b-selected"), selectedItem =>{
					selectedItem.classList.remove('iii-b-selected');
					selectedItem.querySelector('input').checked = false;
				}); 
				target.classList.add('iii-b-selected');           
				radioButton.checked = true;
				
			});
		});

	});  


	window.addEventListener("DOMContentLoaded", async () => {



		TaskSchedulerInstance.addTask(() => {
			// TODO: Refactor
			[].forEach.call(document.querySelectorAll(".iii-c-navbar__menu .iii-c-menu-horizontal__item a"), el => {

				el.addEventListener('click', event =>{
					const menu = el.parentNode.parentNode;
					menu.classList.remove('iii-b-opened');
					const {hash} = event.currentTarget;
					const target = document.querySelector(hash);			
					if(target){
						event.preventDefault();
						window.scrollTo({top: target.offsetTop, behavior: 'smooth'});
					}	
				});
				
			});

		});


		TaskSchedulerInstance.addTask(() => {
			const menuScroll = new MenuScroll();
			menuScroll.init();
			menuScroll.register('.iii-c-navbar__menu .iii-c-menu-horizontal__item a');
		});


		/**
		 * Mobile header sticked
		 */
		TaskSchedulerInstance.addTask( () => {
			$(window).on('resize',() => {

				if (0 < $(window).scrollTop()) {
					$('body').addClass('nav-sticked');
				}
		
				$(window).on('scroll', () => {
		
					if ( 0 < $(window).scrollTop() ) {
						$('body').addClass('nav-sticked');
					} else {
						$('body').removeClass('nav-sticked');
					}
		
				});
		
			}).trigger('resize');		
		});


		TaskSchedulerInstance.schedule();

		const svgElement = document.querySelector('#expertises-svg');
		const anchors = [
			{position: 0.11},
			{position: 0.295 },
			{position: 0.629},
			{position: 0.8157},
			{position: 0.967}
		];

		let targetId = 0;
		let currentId = 0;

		if(svgElement){
			
			const {gsap} = await import(
				/* webpackChunkName: "gsap" */
				'gsap');
			const { MotionPathPlugin } = await import(
				/* webpackChunkName: "gsapMotionPathPlugin" */
				'gsap/MotionPathPlugin') ;
			gsap.registerPlugin(MotionPathPlugin);
			

			TaskSchedulerInstance.addTask(() => {
				// TODO : update svg
				[].forEach.call(document.querySelectorAll("#expertises .iii-b-expandable"), el => {
					
					el.addEventListener('click', () => {
						const animationNode = document.querySelector('#expertises .expertises-animation');
						if(el.classList.contains('iii-b-opened')){
							el.classList.remove('iii-b-opened');
							if(animationNode){
								animationNode.classList.remove('iii-b-active');
							}
						} else {
							[].forEach.call(document.querySelectorAll(".iii-b-expandable"), expandableElement => {
								expandableElement.classList.remove('iii-b-opened');
							});
							el.classList.add('iii-b-opened');
							if(animationNode){
								animationNode.classList.add('iii-b-active');
							}
							if(el.dataset.target && gsap){
	
								targetId = el.dataset.target-1;
								const current = anchors[currentId];
								const target = anchors[targetId];
								const tween = gsap.to("#rect", {duration: 1.5, motionPath: { fromCurrent: true, path: "#path", align: "#path", alignOrigin: [0.5, 0.5], start: current.position, end: target.position  }, paused: true, onComplete: () =>{ currentId = targetId } });
								tween.progress(0);
								tween.play();
				
							}
	
						}
					});
					
				});
	
			});			

			const playBtn = document.getElementById("play");
			if(playBtn && gsap){
				playBtn.addEventListener('click', () => {
					if(targetId < anchors.length -1)
						targetId+=1;
					else {
						targetId = 0;
					}
					const current = anchors[currentId];
					const target = anchors[targetId];
					const tween = gsap.to("#rect", {duration: 3, motionPath: { fromCurrent: true, path: "#path", align: "#path", alignOrigin: [0.5, 0.5], start: current.position, end: target.position  }, paused: true, onComplete: () =>{ currentId = targetId } });
					tween.progress(1);
					// tween.play();
				});
			
			}

		}
	



		const clientsSliderOptions = {
			slidesToShow: 4,
			dots: true,
			nextArrow: false,
			prevArrow: false,
			
			responsive: [
				{
					breakpoint: 950,
					settings: {
						centerMode: true,
						slidesToShow: 2,
						centerPadding: '0px',
					}
				},
				{
					breakpoint: 700,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						centerPadding: '20px',
					}
				},
				{
					breakpoint: 370,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						centerPadding: '10px',
					}
				}			
			]
				
		};

		const sliderPhotosMission = {
			dots: true,
			nextArrow: false,
			prevArrow: false
		}

		const sliderPhotosOrganisation = {
			dots: true,
			nextArrow: false,
			prevArrow: false,
			centerMode: true,
			centerPadding: '0px'
		}
		

		const sliderTabsClient = {
			slidesToShow: 1,
			dots: false,
			nextArrow: '.iii-c-slider__next',
			prevArrow: '.iii-c-slider__prev',
			variableWidth: true,
			infinite: true,
			centerMode: true,
			centerPadding:'15px',
			responsive: [
				{
					breakpoint: 579,
					settings: {
						swipeToSlide: true
					}
				},
			],
			asNavFor: '#tab_logos_clients .iii-c-tabs__content-container'
			
		};

		const sliderLogosClient = {
			slidesToShow: 1,
			dots: false,
			nextArrow: false,
			prevArrow: false,
			infinite: true,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 1,
					}
				},
			],
			asNavFor: '#tab_logos_clients .iii-c-tabs__header-container'
			
		};

		const sliders = new Sliders();
		sliders.init();
		TaskSchedulerInstance.addTask(()=>{
			sliders.registerSlider('#slider-clients .iii-c-slider__slides', clientsSliderOptions);
		});
		TaskSchedulerInstance.addTask(()=>{
			sliders.registerSlider('#mission .iii-c-slider__slides', sliderPhotosMission);
		});
		TaskSchedulerInstance.addTask(()=>{
			sliders.registerSlider('.iii-c-organisation .iii-c-slider__slides', sliderPhotosOrganisation);
		});

		TaskSchedulerInstance.addTask(() => {
			sliders.registerSlider('#tab_logos_clients .iii-c-tabs__header-container', sliderTabsClient);
			sliders.registerSlider('#tab_logos_clients .iii-c-tabs__content-container', sliderLogosClient);
		});




		TaskSchedulerInstance.addTask(() =>{
			$('#tab_logos_clients .iii-c-tabs__header-container').on('init', () => {		
				[].forEach.call(document.querySelectorAll("#tab_logos_clients .iii-c-tabs__header-container .slick-slide"), el => {
					el.addEventListener('click', slide => {
						const slideNode = slide.currentTarget;
						const {slickIndex} = slideNode.dataset;			
						if(slickIndex){
							$('#tab_logos_clients .iii-c-tabs__header-container').slick('slickGoTo',slickIndex, false);
						}					
					});
						
				});
			});

			$('#tab_logos_clients .iii-c-tabs__header-container').on('reinit', () => {		
				[].forEach.call(document.querySelectorAll("#tab_logos_clients .iii-c-tabs__header-container .slick-slide"), el => {				
					el.addEventListener('click', slide => {
						const slideNode = slide.currentTarget;
						const {slickIndex} = slideNode.dataset;			
						if(slickIndex){
							$('#tab_logos_clients .iii-c-tabs__header-container').slick('slickGoTo',slickIndex, false);
						}					
					});
						
				});
			});

		});


		TaskSchedulerInstance.addTask(() => {
			// eslint-disable-next-line
			// if(document.querySelector('#tab_logos_clients')) new Tabs('#tab_logos_clients');
			// eslint-disable-next-line
			if(document.querySelector('#tab_organisations')) new Tabs('#tab_organisations');
		});


		TaskSchedulerInstance.addTask(() =>{

			[].forEach.call(document.querySelectorAll("#slider-clients .iii-c-card-rounded"), el => {
				el.addEventListener('click', event => {				
					const card = event.currentTarget;
					card.classList.toggle('iii-b-selected');
				})
			});

		});


		TaskSchedulerInstance.addTask(() => {
			const menuCloseBtn = document.querySelector('.iii-c-menu-horizontal__close');
			if(menuCloseBtn){
				menuCloseBtn.addEventListener('click', () => {
					menuCloseBtn.parentNode.classList.remove('iii-b-opened');
				});		
			}
		
			const burgerBtn = document.querySelector('.iii-c-navbar__menu .iii-c-icon-btn--burger');
			if(burgerBtn){
				burgerBtn.addEventListener('click', () => {
					const menu = document.querySelector('.iii-c-menu-horizontal');
					if(menu){
						menu.classList.toggle('iii-b-opened');
					}
				});
			}
		});



		TaskSchedulerInstance.addTask(() => {
			[].forEach.call(document.querySelectorAll(".iii-c-organisation .iii-b-expandable"), el => {

				el.addEventListener('click', () => {
					if(el.classList.contains('iii-b-opened')){
						el.classList.remove('iii-b-opened');
					} else {
						[].forEach.call(document.querySelectorAll(".iii-b-expandable"), expandableElement => {
							expandableElement.classList.remove('iii-b-opened');
						});
						el.classList.add('iii-b-opened');	
					}
				});
				
			});	
		});



		TaskSchedulerInstance.schedule();



	});
}

main();