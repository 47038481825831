/**
 * Tabs
 * Gestion des onglets
 */


function Tabs(selector) {
	if (!(this instanceof Tabs)) {
		return new Tabs();
	}

	this.rootElement = document.querySelector(selector);

	return this.setup.call(this);
}

Tabs.prototype = {

	setup(){

		[].forEach.call(this.rootElement.querySelectorAll(".iii-c-tabs__header-item"), el => {
			el.addEventListener('click', e => {
				e.preventDefault();
				
				const tab = e.currentTarget.parentNode;
				const {target} = tab.dataset;

				if(tab.classList.contains('iii-b-selected'))
					return;

				if (!tab.classList.contains('iii-b-selected')) {
					[].forEach.call(this.rootElement.querySelectorAll('.iii-b-selected'), elem => {
						elem.classList.remove('iii-b-selected');
					});
				}
				this.rootElement.querySelector('.iii-c-tabs__content-container').classList.toggle('iii-b-selected');
				tab.classList.toggle('iii-b-selected');
				this.rootElement.querySelector(target).classList.toggle('iii-b-selected');

			});

		});

		/*
		[].forEach.call(document.querySelectorAll("#tab_organisations .iii-c-tabs__header-item"), el => {
			el.addEventListener('click', e => {
				e.preventDefault();
				
				const tab = e.currentTarget.parentNode;
				const {target} = tab.dataset;
				if (!tab.classList.contains('iii-b-selected')) {
					[].forEach.call(document.querySelectorAll('#tab_organisations .iii-b-selected'), elem => {
						elem.classList.remove('iii-b-selected');
					});
				}
				document.querySelector('#tab_organisations .iii-c-tabs__content-container').classList.toggle('iii-b-selected');
				tab.classList.toggle('iii-b-selected');
				document.querySelector(target).classList.toggle('iii-b-selected');

			});

		});
		*/
	}

};

export default Tabs;