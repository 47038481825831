class MenuScroll{

	constructor(){

		this.observerOptions = {
			rootMargin: '0px',
			threshold: 0.5
		};

		this.menuItems = [];

		this.observer = null;

	}

	init() {    

		this.observer = new IntersectionObserver( entries =>{
			entries.forEach( entry => {
				if ( 0 < entry.intersectionRatio) {
					this.menuItems.forEach( item => {
						if(item.target.isSameNode(entry.target)){
							item.element.classList.add('iii-b-visible');
						} else {
							item.element.classList.remove('iii-b-visible');
						}
					});

				} 
			});


		}, this.observerOptions);
	}

	register(selector){

		[].forEach.call(document.querySelectorAll(selector), element => {
			if(element){
				const {hash} = element;
				const target = document.querySelector(hash);
				if(target){
					this.observer.observe(target);
					const entry = {element: element.parentNode, target};
					this.menuItems.push(entry);
				}

			} else{
				console.log(`${selector} not found`)
			}
		});

		
	}

}

export default MenuScroll;