/* global $ */
class Sliders{

	constructor(){

		this.observerOptions = {
			rootMargin: '0px',
			threshold: 0.0
		};

		this.sliders = [];

		this.observer = null;

	}

	init() {    

		this.observer = new IntersectionObserver( (entries, observer) =>{
			entries.forEach( entry => {
				if ( 0 < entry.intersectionRatio) {
					// Load slider
					this.sliders.forEach( slider => {
						if(slider.element.isSameNode(entry.target)){
							$(slider.element).slick(slider.option);
							observer.unobserve(slider.element);
						}
					});

				} 
			});


		}, this.observerOptions);
	}

	/*
	schedule(){

		function animateValue(obj, start, end, duration) {
			let startTimestamp = null;
			const step = (timestamp) => {
			  if (!startTimestamp) startTimestamp = timestamp;
			  const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			  obj.innerHTML = Math.floor(progress * (end - start) + start);
			  if (progress < 1) {
				window.requestAnimationFrame(step);
			  }
			};
			window.requestAnimationFrame(step);
		  }
		  
		  const obj = document.getElementById("value");
		  animateValue(obj, 100, 0, 5000);

	}
			  */

	registerSlider(selector, opt){

		[].forEach.call(document.querySelectorAll(selector), element => {
			if(element){
				this.observer.observe(element);
				const entry = {element, option: opt};
				this.sliders.push(entry);
			} else{
				console.log(`${selector} not found`)
			}
		});

		
	}

}

export default Sliders;