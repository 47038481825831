class TaskScheduler {

	constructor(){
		this.tasks = [];
	}


	addTask(fn) {
		this.tasks.push(fn);
	}

	schedule(){
		requestIdleCallback( deadline => {
			this.executeTasks(deadline);
		});
	}

	executeTasks(deadline){
		// Use any remaining time, or, if timed out, just run through the tasks.
		while (( 0 < deadline.timeRemaining() || deadline.didTimeout) && 0 < this.tasks.length) {
			// console.log(`Time remaining: ${deadline.timeRemaining()}, Remaining Tasks ${this.tasks.length}`);
			this.tasks[0]();
			this.tasks.shift();

		}

		if (0 < this.tasks.length )
			requestIdleCallback( deadline2 => {
				this.executeTasks(deadline2);
			});
	}



}

const TaskSchedulerInstance = new TaskScheduler();
Object.freeze(TaskSchedulerInstance);
export default TaskSchedulerInstance ;